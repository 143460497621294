<template>
  <a-layout-content>
    <div class="nav">
      <a-breadcrumb>
        <a-breadcrumb-item>产品管理</a-breadcrumb-item>
        <a-breadcrumb-item>整车产品列表</a-breadcrumb-item>
      </a-breadcrumb>
    </div>
    <div class="content-container">
      <common-search
        :fields="fields"
        @search="onSearch"
        ref="search"
      ></common-search>
      <a-row class="button-container-second" v-if="isOperationHeadquarters">
        <a-space>
          <a-button type="primary" @click="onSet">
            设置整车采购标准
          </a-button>
          <a-button type="primary" @click="onNew">新增</a-button>
          <a-button @click="onBatchChange(true)">批量上架</a-button>
          <a-button @click="onBatchChange(false)">批量下架</a-button>
          <import-product :factory-list="factoryList" @reset="onReset" />
          <a-button type="link" icon="download" :href="downloadTemplate">
            下载导入模板
          </a-button>
          <a-popconfirm
            title="是否确认生成实时价格?"
            ok-text="确认"
            cancel-text="取消"
            @confirm="initPolicy"
          >
            <a-button :loading="initPolicyLoading">
              生成实时价格
            </a-button>
          </a-popconfirm>
        </a-space>
      </a-row>
      <div class="main-content">
        <a-table
          :data-source="tblData"
          :columns="tblColumns"
          :pagination="pagination"
          :loading="loading"
          row-key="productId"
          :row-selection="
            isOperationHeadquarters
              ? {
                  selectedRowKeys: selectedRowKeys,
                  onChange: onSelectChange
                }
              : null
          "
          @change="onPage"
        >
          <div
            slot="productImg"
            slot-scope="text, record"
            v-viewer
            class="product-img-container"
          >
            <div
              v-if="record.productImages && record.productImages.length !== 0"
              class="product-img-container"
            >
              <img
                v-if="!record.productImages[0].thumbUrl"
                :src="imageBaseUrl + record.productImages[0].fileUrl"
                :data-source="imageBaseUrl + record.productImages[0].fileUrl"
                alt="产品图片"
              />
              <img
                v-else
                :src="imageBaseUrl + record.productImages[0].thumbUrl"
                :data-source="imageBaseUrl + record.productImages[0].fileUrl"
                alt="产品图片"
              />
            </div>
            <div v-else></div>
          </div>
          <a-space slot="action" slot-scope="text, record">
            <a @click="onDetail(record)">详情</a>
            <template v-if="isOperationHeadquarters">
              <a v-if="record.enableFlag" @click="onEnableFlagUpdate(record)">
                下架
              </a>
              <a v-else @click="onEnableFlagUpdate(record)">上架</a>
              <a-popconfirm
                title="确认是否删除?"
                ok-text="确定"
                cancel-text="取消"
                @confirm="onDelete(record)"
              >
                <a v-if="!record.enableFlag" class="btn-operation">删除</a>
              </a-popconfirm>
            </template>
          </a-space>
        </a-table>
      </div>
    </div>

    <!--  设置整车采购标准弹窗  -->
    <a-modal
      v-model="setStandardVisible"
      title="整车采购标准"
      @ok="onSetStandard"
      @cancel="handleCancel"
    >
      <div class="set-standard-container">
        <a-input v-model="purchaseMinVolume" class="input-standard" />
        <div class="unit">m³</div>
        <div class="standard-word">≤ 商品装运总体积 ≤</div>
        <a-input v-model="purchaseMaxVolume" class="input-standard" />
        <div class="unit">m³</div>
      </div>
      <div class="set-standard-container-two">
        <a-input v-model="purchaseMinWeight" class="input-standard" />
        <div class="unit">kg</div>
        <div class="standard-word">≤ 商品总重量 ≤</div>
        <a-input v-model="purchaseMaxWeight" class="input-standard" />
        <div class="unit">kg</div>
      </div>
    </a-modal>
  </a-layout-content>
</template>

<script>
import moment from "moment";
import {
  deleteProduct,
  fetchAllFactoriesList,
  fetchProductList,
  fetchStandardOperation,
  modifyProductStatus,
  setStandard
} from "@/services/ProductService";
import { imageBaseUrl } from "@/services/HttpService";
import ImportProduct from "./ImportProduct.vue";
import { pageDetailMixins } from "@/components/pageDetailMixin";
import { strategyInitPolicy } from "@/services/ValueBag";

const tblColumns = [
  {
    title: "产品图",
    key: "productImg",
    scopedSlots: { customRender: "productImg" },
    width: "10%"
  },
  {
    title: "产品名称",
    dataIndex: "productName"
  },
  {
    title: "产品编码",
    dataIndex: "productCode"
  },
  {
    title: "产品状态",
    dataIndex: "productStatus"
  },
  {
    title: "发布时间",
    dataIndex: "createTime"
  },
  {
    title: "操作",
    key: "action",
    scopedSlots: { customRender: "action" }
  }
];
export default {
  components: {
    ImportProduct
  },
  mixins: [pageDetailMixins],
  data() {
    return {
      initPolicyLoading: false,
      fields: [
        {
          label: "产品名称",
          key: "productName",
          component: "j-input"
        },
        {
          label: "产品编码",
          key: "productCode",
          component: "j-input"
        },
        {
          label: "产品状态",
          key: "productStatus",
          component: "j-select",
          defaultValue: "-1",
          options: [
            { value: "-1", label: "全部" },
            { value: "1", label: "上架" },
            { value: "0", label: "下架" }
          ]
        }
      ],
      downloadTemplate: `${process.env.BASE_URL}产品导入模板.xlsx`,
      imageBaseUrl,
      factoryList: [],
      form: {
        productStatus: -1
      },
      statusList: [
        { value: -1, label: "全部" },
        { value: 1, label: "上架" },
        { value: 0, label: "下架" }
      ],
      tblData: [],
      tblColumns,
      pagination: {
        hideOnSinglePage: true
      },
      loading: false,
      selectedRowKeys: [],
      setStandardVisible: false,
      purchaseMaxVolume: "",
      purchaseMaxWeight: "",
      purchaseMinVolume: "",
      purchaseMinWeight: "",
      purchaseMaxVolumes: "",
      purchaseMaxWeights: "",
      purchaseMinVolumes: "",
      purchaseMinWeights: "",
      isOperationHeadquarters: true
    };
  },
  created() {
    this.isOperationHeadquarters = (
      localStorage.getItem("roles") || []
    ).includes("OperationHeadquartersAdmin");
    if (this.isOperationHeadquarters) {
      this.fields.push({
        label: "制造原厂",
        key: "factoryId",
        component: "j-select-search",
        options: [],
        getOptions: () => {
          return this.onLoadFactory();
        }
      });
    }
  },
  mounted() {
    this.onLoad();
    this.onFetchStandard();
  },
  activated() {
    if (this.$store.state.hasChange) {
      this.onLoad();
      this.$store.state.hasChange = false;
    }
  },
  methods: {
    //更新价格
    initPolicy() {
      this.initPolicyLoading = true;
      strategyInitPolicy().then(resp => {
        this.initPolicyLoading = false;
        if (resp.data.code === "SUCCESS") {
          this.$message.success("操作成功");
        } else {
          this.$message.error(resp.data.errorMsg);
        }
      });
    },
    // 获取全部制造原厂
    async onLoadFactory() {
      const resp = await fetchAllFactoriesList();
      if (resp.data.code === "SUCCESS") {
        const options = [];
        this.factoryList = [];
        resp.data.data.records.forEach(item => {
          options.push({
            label: item.factoryName,
            value: item.factoryId
          });
          this.factoryList.push({
            factoryId: item.factoryId,
            factoryName: item.factoryName
          });
        });
        return options;
      }
    },
    onSearch(form) {
      this.pagination.current = 1;
      const params = {
        pageNum: this.pagination.current,
        pageSize: this.pagination.pageSize,
        ...form,
        productType: "整车",
        factoryId: this.isOperationHeadquarters
          ? this.form.factoryId
          : localStorage.getItem("corporationId")
      };

      this.fetchList(params);
    },
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys;
    },
    onSet() {
      this.setStandardVisible = true;
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    handleCancel() {
      this.setStandardVisible = false;
      this.purchaseMinVolume = this.purchaseMinVolumes;
      this.purchaseMaxVolume = this.purchaseMaxVolumes;
      this.purchaseMinWeight = this.purchaseMinWeights;
      this.purchaseMaxWeight = this.purchaseMaxWeights;
    },
    // 设置采购标准
    onSetStandard() {
      if (
        this.purchaseMaxVolume !== "" &&
        this.purchaseMinVolume !== "" &&
        this.purchaseMaxWeight !== "" &&
        this.purchaseMinWeight !== ""
      ) {
        const params = {
          purchaseMaxVolume: Number(this.purchaseMaxVolume),
          purchaseMinVolume: Number(this.purchaseMinVolume),
          purchaseMaxWeight: Number(this.purchaseMaxWeight),
          purchaseMinWeight: Number(this.purchaseMinWeight)
        };
        setStandard(params).then(resp => {
          if (resp.data.code === "SUCCESS") {
            this.$message.success("设置成功");
            this.onFetchStandard();
            this.setStandardVisible = false;
          } else {
            this.purchaseMinVolume = this.purchaseMinVolumes;
            this.purchaseMaxVolume = this.purchaseMaxVolumes;
            this.purchaseMinWeight = this.purchaseMinWeights;
            this.purchaseMaxWeight = this.purchaseMaxWeights;
          }
        });
      } else {
        this.$message.info("请填写完整");
      }
    },
    // 查询采购标准
    onFetchStandard() {
      fetchStandardOperation().then(resp => {
        if (resp.data.code === "FAILED") {
          this.$message.error(resp.data.errorMsg);
        } else {
          const standard = resp.data.data;
          if (typeof standard === "undefined" || !standard) {
            this.$message.error("该原厂未设置采购标准");
          } else {
            this.purchaseMinVolume = standard.purchaseMinVolume;
            this.purchaseMaxVolume = standard.purchaseMaxVolume;
            this.purchaseMinWeight = standard.purchaseMinWeight;
            this.purchaseMaxWeight = standard.purchaseMaxWeight;
            this.purchaseMinVolumes = standard.purchaseMinVolume;
            this.purchaseMaxVolumes = standard.purchaseMaxVolume;
            this.purchaseMinWeights = standard.purchaseMinWeight;
            this.purchaseMaxWeights = standard.purchaseMaxWeight;
          }
        }
      });
    },
    onNew() {
      this.$router.push({
        name: "CompleteVehicleAdd"
      });
    },
    onLoad() {
      console.log(this.form, this.isOperationHeadquarters);
      const params = {
        pageNum: this.pagination.current ? this.pagination.current : 1,
        pageSize: this.pagination.pageSize ? this.pagination.pageSize : 15,
        productCode: this.form.productCode ? this.form.productCode : "",
        productName: this.form.productName ? this.form.productName : "",
        productStatus: this.form.productStatus ? this.form.productStatus : "",
        factoryId: this.isOperationHeadquarters
          ? this.form.factoryId
          : localStorage.getItem("corporationId"),
        productType: "整车"
      };
      this.fetchList(params);
    },
    fetchList(params) {
      this.loading = true;
      fetchProductList(params)
        .then(resp => {
          this.loading = false;
          if (resp.data.code === "SUCCESS") {
            const data = resp.data.data;
            this.tblData = data.records;
            this.tblData.forEach(item => {
              if (item.enableFlag) item.productStatus = "上架";
              else item.productStatus = "下架";
              item.createTime = moment(item.createTime).format(
                "YYYY-MM-DD HH:mm:ss"
              );
            });
            const pagination = { ...this.pagination };
            pagination.current = data.pageNum;
            pagination.total = data.total;
            pagination.pageSize = data.pageSize;
            pagination.showTotal = function(total, range) {
              return `当前显示 ${range[0]} - ${range[1]} 条, 共 ${total} 条`;
            };
            this.pagination = pagination;
          }
        })
        .catch(error => {
          if (error) {
            this.loading = false;
          }
        });
    },
    onPage(pagination) {
      this.pagination.current = pagination.current;
      this.onLoad();
    },
    onBatchChange(status) {
      if (this.selectedRowKeys.length === 0) {
        this.$message.error("请至少选择一个产品");
        return;
      }
      const param = {
        enableFlag: status,
        productIds: this.selectedRowKeys
      };
      modifyProductStatus(param)
        .then(resp => {
          if (resp.data.code === "SUCCESS") {
            this.$message.success("操作成功");
            this.selectedRowKeys = [];
            this.onLoad();
          }
        })
        .catch(error => {
          if (error) {
            console.log(error);
          }
        });
    },
    onDetail(record) {
      this.$router.push({
        name: "CompleteVehicleDetail",
        params: { productId: record.productId }
      });
    },
    onEnableFlagUpdate(record) {
      const param = {
        enableFlag: !record.enableFlag,
        productIds: [record.productId]
      };
      modifyProductStatus(param)
        .then(resp => {
          if (resp.data.code === "SUCCESS") {
            this.$message.success("操作成功");
            this.selectedRowKeys = [];
            this.onLoad();
          }
        })
        .catch(error => {
          if (error) {
            console.log(error);
          }
        });
    },
    onDelete(record) {
      const hide = this.$message.loading("产品删除中", 0);
      deleteProduct(record.productId)
        .then(resp => {
          if (resp.data.code === "SUCCESS") {
            this.$message.success("删除成功");
            this.selectedRowKeys = [];
            this.onSearch();
          } else {
            this.$message.success("删除失败");
          }
          setTimeout(hide, 0);
        })
        .catch(error => {
          if (error) {
            console.log(error);
          }
          this.$message.success("删除失败");
          setTimeout(hide, 0);
        });
    },
    onReset() {
      this.$refs.search.onReset();
    }
  }
};
</script>

<style scoped>
.button-container-second {
  margin-bottom: 20px;
}
.content-container {
  min-height: 1000px;
  background-color: white;
  margin: 30px;
}
.set-standard-container {
  display: flex;
  align-items: flex-end;
}
.input-standard {
  width: 100px;
  margin-right: 10px;
}
.standard-word {
  min-width: 120px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 10px;
}
.set-standard-container-two {
  display: flex;
  align-items: flex-end;
  margin-top: 10px;
}
.unit {
  width: 20px;
}
.product-img-container {
  width: 100px;
  height: 100px;
}
.product-img-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.btn-operation {
  cursor: pointer;
}
.col {
  flex: 1;
  display: flex;
  align-items: center;
}
</style>
