<template>
  <a-layout-content>
    <div class="nav">
      <a-breadcrumb>
        <a-breadcrumb-item>产品管理</a-breadcrumb-item>
        <a-breadcrumb-item>
          <span class="breadcrumb-title" @click="onBack">整车产品列表</span>
        </a-breadcrumb-item>
        <a-breadcrumb-item>{{ breadcrumbTitle }}</a-breadcrumb-item>
      </a-breadcrumb>
    </div>
    <div class="info-container">
      <a-row>
        <a-col><div class="info-title">产品信息</div></a-col>
      </a-row>
      <a-form :form="infoForm">
        <a-row>
          <a-col :span="8">
            <a-form-item label="产品编码" class="form-item">
              <a-input
                v-decorator="[
                  'productCode',
                  { rules: [{ required: true, message: '请输入产品编码' }] }
                ]"
                :disabled="disabled"
              />
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="产品名称" class="form-item">
              <a-input
                v-decorator="[
                  'productName',
                  { rules: [{ required: true, message: '请输入产品名称' }] }
                ]"
                :disabled="disabled"
              />
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="产品类型" class="form-item">
              整车
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="8">
            <a-form-item label="制造原厂" class="form-item">
              <a-select
                v-decorator="[
                  'factoryId',
                  { rules: [{ required: true, message: '请选择制造原厂' }] }
                ]"
                class="input"
                show-search
                allow-clear
                :filter-option="filterOption"
              >
                <a-select-option
                  v-for="item in factoryList"
                  :key="item.factoryId"
                >
                  {{ item.factoryName }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <div class="pro-status">
      <div class="info-title">产品状态</div>
      <a-radio-group v-model="onShelfStatus" :disabled="disabled">
        <a-radio value="1" disabled>
          上架
        </a-radio>
        <a-radio value="0">
          下架
        </a-radio>
      </a-radio-group>
    </div>
    <div class="commodity-specifications">
      <div class="info-title">商品规格</div>
      <a-tag
        v-if="buttonName !== '修改'"
        class="add-speci-tag"
        @click="addSpeci"
      >
        添加规格
      </a-tag>
      <div v-if="!isExistSpec">
        <div class="tag-name-container">
          版本
        </div>
        <add-tag
          :list="versionList"
          tag-name="版本"
          :show="buttonName !== '修改'"
          @addTag="onAddVersionTag"
          @removeTag="onRemoveVersionTag"
        />
      </div>
      <div
        v-for="(item, index) in speciList"
        :key="index"
        class="commodity-content"
      >
        <div class="tag-name-container">
          {{ item.tagName }}
          <img
            v-if="buttonName !== '修改' && item.tagName !== '版本'"
            src="../../assets/imgs/delete_icon.png"
            alt="删除"
            @click="onDeleteSpeci(index)"
          />
          <div
            class="tag-name-button"
            v-if="index !== 0"
            @click="moveUpSpeci(index)"
          >
            上移
          </div>
          <div
            class="tag-name-button"
            v-if="index !== speciList.length - 1"
            @click="moveDownSpeci(index)"
          >
            下移
          </div>
        </div>
        <add-tag
          :list="item.list"
          :tag-name="item.tagName"
          :show="buttonName !== '修改'"
          @addTag="onAddTags"
          @removeTag="onRemoveTags"
        />
      </div>
      <div v-if="speciVisible" class="add-speci-container">
        <a-form
          :form="speciForm"
          class="advanced-search-form"
          @submit="onAddSpeci"
        >
          <a-row>
            <a-col :span="6">
              <a-form-item label="规格名">
                <a-input
                  v-decorator="[
                    'tagName',
                    {
                      rules: [{ required: true, message: '请输入规格名' }]
                    }
                  ]"
                  placeholder="请填入规格名"
                />
              </a-form-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="6">
              <a-form-item label="规格值">
                <a-input
                  v-decorator="[
                    'value',
                    {
                      rules: [{ required: true, message: '请输入规格值' }]
                    }
                  ]"
                  placeholder="请填入规格值"
                />
              </a-form-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="2">
              <a-button type="primary" html-type="submit">确定</a-button>
            </a-col>
            <a-col :span="2">
              <a-button @click="speciVisible = false">取消</a-button>
            </a-col>
          </a-row>
        </a-form>
      </div>
    </div>
    <div class="commodity-info">
      <div class="commodity-title">
        <div class="info-title">商品信息</div>
        <a-button type="primary" @click="onJudgeAddSku">
          新增商品
        </a-button>
        <a-modal
          title=""
          :visible="visible"
          :confirm-loading="confirmLoading"
          @ok="handleOk"
          @cancel="handleCancel"
        >
          {{ ModalText }}
        </a-modal>
      </div>
      <a-table
        class="commodity-table"
        :data-source="tblData"
        :columns="tableColumns"
        :pagination="false"
        :loading="loading"
        row-key="skuCode"
        :scroll="{ x: 1300 }"
      >
        <template slot="hasSn" slot-scope="text">
          {{ text ? "是" : "否" }}
        </template>
        <template slot="hasBatchNo" slot-scope="text">
          {{ text ? "是" : "否" }}
        </template>
        <div
          slot="skuImages"
          v-viewer
          slot-scope="text, record"
          class="img-container"
        >
          <img
            v-if="record.skuImages && record.skuImages.length > 0"
            :src="imageBaseUrl + record.skuImages[0].fileUrl"
            :data-source="imageBaseUrl + record.skuImages[0].fileUrl"
            alt="产品图片"
          />
          <div v-else></div>
        </div>

        <a-space slot="action" slot-scope="text, record">
          <a @click="onDeleteTable(record)">删除</a>
          <a @click="onModifyVisible(record)">修改</a>
          <a v-if="record.bom.length === 0" @click="onDetailBOM(record)">
            添加BOM表
          </a>
          <a v-else @click="onDetailBOM(record)">修改BOM表</a>
        </a-space>
      </a-table>
    </div>
    <div class="commodity-introduce">
      <div class="info-title">产品介绍</div>
      <ckeditor v-model="proIntroduce" :config="editorConfig"></ckeditor>
    </div>

    <div class="examine-container">
      <a-button class="button-item" @click="onAdd">
        完成
      </a-button>
    </div>

    <!--修改商品信息弹窗-->
    <a-modal v-model="modifyVisible" title="修改商品信息" @ok="onModifyComm">
      <a-form :form="modifyForm" class="advanced-search-form">
        <a-form-item label="商品编码">
          {{ skuCode }}
        </a-form-item>
        <a-form-item label="版本" v-if="showRow()">
          <a-select
            v-decorator="[
              `keyPropsMap.版本`,
              { rules: [{ required: true, message: `请输入版本` }] }
            ]"
            :options="versionList"
            :placeholder="`请输入版本`"
          />
        </a-form-item>
        <a-form-item
          v-for="(item, index) in speciList"
          :key="index"
          :label="item.tagName"
        >
          <a-select
            v-decorator="[
              `keyPropsMap.${item.tagName}`,
              { rules: [{ required: true, message: `请输入${item.tagName}` }] }
            ]"
            :options="item.list"
            :placeholder="`请输入${item.tagName}`"
          />
        </a-form-item>
        <a-form-item label="商品图" extra="图片大小限制在20MB以内">
          <a-upload
            v-decorator="[
              'skuImgs',
              {
                rules: [{ required: true, message: '请上传商品图' }],
                initialValue: skuImgs
              }
            ]"
            name="file"
            list-type="picture-card"
            accept=".png,.jpg,.jpeg,.gif,.svg"
            :show-upload-list="true"
            :action="imgUrl"
            :file-list="skuImgs"
            :before-upload="beforeUploadFile"
            @preview="handlePreviewSkuImage"
            @change="handleChangeSkuImg"
          >
            <div v-if="skuImgs.length < 8">
              <a-icon type="plus" />
            </div>
          </a-upload>
          <a-modal
            style="z-index: 99"
            :visible="previewVisible"
            @ok="previewVisible = false"
            @cancel="previewVisible = false"
          >
            <img
              :src="previewImage"
              alt="logo"
              style="width: 100%"
              :footer="null"
            />
          </a-modal>
        </a-form-item>
        <a-form-item label="出厂价">
          <a-input
            v-decorator="[
              'wholeSellPrice',
              { rules: [{ required: true, message: '请输入出厂价' }] }
            ]"
            placeholder="请填入出厂价"
          />
        </a-form-item>
        <a-form-item label="零售指导价">
          <a-input
            v-decorator="[
              'retailPrice',
              { rules: [{ required: true, message: '请输入零售指导价' }] }
            ]"
            placeholder="请填入零售指导价"
          />
        </a-form-item>
        <a-form-item label="基础价">
          <a-input
            v-decorator="[
              'platformPrice',
              { rules: [{ required: true, message: '请输入基础价' }] }
            ]"
            placeholder="请填入基础价"
          />
        </a-form-item>
        <a-form-item label="是否具有序列号">
          <a-select
            v-decorator="[
              'hasSn',
              {
                rules: [{ required: true, message: '请选择是否具有序列号' }],
                initialValue: 1
              }
            ]"
            :options="[
              { label: '是', value: 1 },
              { label: '否', value: 0 }
            ]"
          ></a-select>
        </a-form-item>
        <a-form-item label="是否具有批次号">
          <a-select
            v-decorator="[
              'hasBatchNo',
              {
                rules: [{ required: true, message: '请选择是否具有批次号' }],
                initialValue: 1
              }
            ]"
            :options="[
              { label: '是', value: 1 },
              { label: '否', value: 0 }
            ]"
          ></a-select>
        </a-form-item>
        <a-form-item label="商品体积">
          <a-input
            v-decorator="[
              'skuVolume',
              { rules: [{ required: true, message: '请输入商品体积' }] }
            ]"
            placeholder="请填入商品体积"
            suffix="m³"
          />
        </a-form-item>
        <a-form-item label="装运体积">
          <a-input
            v-decorator="[
              'packVolume',
              { rules: [{ required: true, message: '请输入装运体积' }] }
            ]"
            placeholder="请填入装运体积"
            suffix="m³"
          />
        </a-form-item>
        <a-form-item label="商品重量">
          <a-input
            v-decorator="[
              'skuWeight',
              { rules: [{ required: true, message: '请输入商品重量' }] }
            ]"
            placeholder="请填入商品重量"
            suffix="kg"
          />
        </a-form-item>
      </a-form>
    </a-modal>

    <!-- 新增商品弹窗   -->
    <a-modal v-model="addVisible" title="新增商品" @ok="onSubmitCommodity">
      <a-form
        :form="addForm"
        class="advanced-search-form"
        @submit="onSubmitCommodity"
      >
        <a-form-item label="商品编码">
          <a-input
            v-decorator="[
              'skuCode',
              { rules: [{ required: true, message: '请输入商品编码' }] }
            ]"
            placeholder="请填入商品编码"
            @blur="onJudgeCommon"
          />
        </a-form-item>
        <a-form-item label="版本" v-if="showRow()">
          <a-select
            v-decorator="[
              `keyPropsMap.版本`,
              { rules: [{ required: true, message: `请输入版本` }] }
            ]"
            :options="versionList"
            :placeholder="`请输入版本`"
          />
        </a-form-item>
        <a-form-item
          v-for="(item, index) in speciList"
          :key="index"
          :label="item.tagName"
        >
          <a-select
            v-decorator="[
              `keyPropsMap.${item.tagName}`,
              { rules: [{ required: true, message: `请输入${item.tagName}` }] }
            ]"
            :options="item.list"
            :placeholder="`请输入${item.tagName}`"
          />
        </a-form-item>
        <a-form-item label="商品图" extra="图片大小限制在20MB以内">
          <a-upload
            v-decorator="[
              'skuImages',
              {
                rules: [{ required: true, message: '请上传商品图' }],
                initialValue: skuImages
              }
            ]"
            name="file"
            list-type="picture-card"
            accept=".png,.jpg,.jpeg,.gif,.svg"
            :show-upload-list="true"
            :action="imgUrl"
            :file-list="skuImages"
            :before-upload="beforeUploadFile"
            @preview="handlePreviewSkuImage"
            @change="handleChangeSkuImage"
          >
            <div v-if="skuImages.length < 8">
              <a-icon type="plus" />
            </div>
          </a-upload>
          <a-modal
            style="z-index: 99"
            :visible="previewVisible"
            @ok="previewVisible = false"
            @cancel="previewVisible = false"
          >
            <img
              :src="previewImage"
              alt="logo"
              style="width: 100%"
              :footer="null"
            />
          </a-modal>
        </a-form-item>
        <a-form-item label="出厂价">
          <a-input
            v-decorator="[
              'wholeSellPrice',
              { rules: [{ required: true, message: '请输入出厂价' }] }
            ]"
            placeholder="请填入出厂价"
          />
        </a-form-item>
        <a-form-item label="零售指导价">
          <a-input
            v-decorator="[
              'retailPrice',
              { rules: [{ required: true, message: '请输入零售指导价' }] }
            ]"
            placeholder="请填入零售指导价"
          />
        </a-form-item>
        <a-form-item label="基础价">
          <a-input
            v-decorator="[
              'platformPrice',
              { rules: [{ required: true, message: '请输入基础价' }] }
            ]"
            placeholder="请填入基础价"
          />
        </a-form-item>
        <a-form-item label="是否具有序列号">
          <a-select
            v-decorator="[
              'hasSn',
              {
                rules: [{ required: true, message: '请选择是否具有序列号' }],
                initialValue: 1
              }
            ]"
            :options="[
              { label: '是', value: 1 },
              { label: '否', value: 0 }
            ]"
          ></a-select>
        </a-form-item>
        <a-form-item label="是否具有批次号">
          <a-select
            v-decorator="[
              'hasBatchNo',
              {
                rules: [{ required: true, message: '请选择是否具有批次号' }],
                initialValue: 1
              }
            ]"
            :options="[
              { label: '是', value: 1 },
              { label: '否', value: 0 }
            ]"
          ></a-select>
        </a-form-item>
        <a-form-item label="商品体积">
          <a-input
            v-decorator="[
              'skuVolume',
              { rules: [{ required: true, message: '请输入商品体积' }] }
            ]"
            placeholder="请填入商品体积"
            suffix="m³"
          />
        </a-form-item>
        <a-form-item label="装运体积">
          <a-input
            v-decorator="[
              'packVolume',
              { rules: [{ required: true, message: '请输入装运体积' }] }
            ]"
            placeholder="请填入装运体积"
            suffix="m³"
          />
        </a-form-item>
        <a-form-item label="商品重量">
          <a-input
            v-decorator="[
              'skuWeight',
              { rules: [{ required: true, message: '请输入商品重量' }] }
            ]"
            placeholder="请填入商品重量"
            suffix="kg"
          />
        </a-form-item>
      </a-form>
    </a-modal>
  </a-layout-content>
</template>

<script>
import { imgUrl, upload } from "@/services/UploadService";
import { baseURL, imageBaseUrl } from "@/services/HttpService";
import {
  addProduct,
  checkCommonSku,
  checkProductCodeExists,
  fetchAllFactoriesList,
  modifyProductDetail
} from "@/services/ProductService";
import AddTag from "@/components/AddTag";

const tblColumns = [
  {
    title: "商品编码",
    dataIndex: "skuCode",
    width: 150
  },
  {
    title: "商品图",
    dataIndex: "skuImages",
    scopedSlots: { customRender: "skuImages" },
    width: 200
  },
  {
    title: "出厂价",
    dataIndex: "wholeSellPrice",
    width: 150
  },
  {
    title: "零售指导价",
    dataIndex: "retailPrice",
    width: 150
  },
  {
    title: "基础价",
    dataIndex: "platformPrice",
    width: 150
  },
  {
    title: "是否具有序列号",
    dataIndex: "hasSn",
    scopedSlots: { customRender: "hasSn" },
    width: 110
  },
  {
    title: "是否具有批次号",
    dataIndex: "hasBatchNo",
    scopedSlots: { customRender: "hasBatchNo" },
    width: 110
  },
  {
    title: "商品体积（m³）",
    dataIndex: "skuVolume",
    width: 150
  },
  {
    title: "装运体积（m³）",
    dataIndex: "packVolume",
    width: 150
  },
  {
    title: "商品重量（kg）",
    dataIndex: "skuWeight",
    width: 150
  },
  {
    title: "操作",
    key: "action",
    fixed: "right",
    scopedSlots: { customRender: "action" },
    width: 300
  }
];

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

export default {
  components: {
    AddTag
  },
  data() {
    return {
      imgUrl,
      previewVisible: false,
      imageBaseUrl,
      ModalText: "",
      visible: false,
      confirmLoading: false,
      infoForm: this.$form.createForm(this),
      onShelfStatus: "0",
      tblData: [],
      factoryList: [],
      tblColumns,
      tableColumns: [],
      newSpeciList: [],
      loading: false,
      proIntroduce: "",
      editorConfig: {
        removePlugins: ["about"],
        removeDialogTabs: "image:advanced;image:Link", // 隐藏超链接与高级选项
        image_previewText: " ", // 预览区域显示内容
        // 该插件使用<div>元素（而不是传统的<iframe>元素）作为主题编辑器中的可编辑区域
        extraPlugins: "divarea",
        filebrowserUploadUrl: `${baseURL}/upload-ckeditor/product_intro_pic`, // 上传图片路径
        // 是否强制复制来的内容去除格式 plugins/pastetext/plugin.js
        forcePasteAsPlainText: true, // 去除
        // 去除内容过滤
        allowedContent: true,
        // 折叠工具栏
        toolbarCanCollapse: true,
        // https://ckeditor.com/docs/ckeditor4/latest/api/CKEDITOR_config.html#cfg-enterMode
        enterMode: 2,
        // 只读模式
        readOnly: false,
        toolbarGroups: [
          { name: "clipboard", groups: ["clipboard", "undo"] },
          { name: "insert" },
          { name: "document", groups: ["mode", "document", "doctools"] },
          { name: "basicstyles", groups: ["basicstyles", "cleanup"] },
          {
            name: "paragraph",
            groups: ["list", "indent", "blocks", "align", "bidi"]
          },
          { name: "links" },
          { name: "styles" }
        ]
      },
      addVisible: false,
      addForm: this.$form.createForm(this),
      previewImage: "",
      skuImages: [],
      breadcrumbTitle: "",
      buttonName: "",
      disabled: false,
      productId: "",
      speciList: [],
      speciVisible: false,
      speciForm: this.$form.createForm(this),
      modifyVisible: false,
      skuCode: "",
      modifyForm: this.$form.createForm(this),
      skuImgs: [],
      imgList: [],
      versionList: [
        {
          label: "标准版",
          value: "标准版"
        }
      ],
      isExistSpec: false
    };
  },
  beforeRouteEnter(to, from, next) {
    if (from.name !== "AddBOM") {
      localStorage.removeItem("tblData");
      localStorage.removeItem("productName");
      localStorage.removeItem("productCode");
      localStorage.removeItem("proIntroduce");
      localStorage.removeItem("keyPropsMap");
      localStorage.removeItem("partCode");
      localStorage.removeItem("specifications");
      localStorage.removeItem("factoryId");
      localStorage.removeItem("onShelfStatus");
    }
    next();
  },
  mounted() {
    this.newSpeciList = [];
    if (
      localStorage.getItem("tblData") &&
      JSON.parse(localStorage.getItem("tblData")).length > 0
    ) {
      JSON.parse(localStorage.getItem("tblData")).forEach(item => {
        this.newSpeciList.push(item.keyPropsMap);
      });
    }
    this.onLoadFactory();
    this.tableColumns = [...this.tblColumns];
    this.breadcrumbTitle = "新增产品";
    this.buttonName = "完成";
    this.disabled = false;
    if (localStorage.getItem("tblData") !== null) {
      this.tblData = JSON.parse(localStorage.getItem("tblData"));
    }
    if (localStorage.getItem("specifications") !== null) {
      this.isExistSpec = true;
      this.speciList = [];
      const specifications = JSON.parse(localStorage.getItem("specifications"));
      for (const key in specifications) {
        const list = [];
        specifications[key].forEach(item => {
          list.push(JSON.parse(item));
        });
        this.speciList.push({ tagName: key, list: list });
        const column = {
          title: key,
          dataIndex: `keyPropsMap.${key}`,
          width: 150
        };
        if (this.tableColumns.indexOf(column) === -1)
          this.tableColumns.splice(2, 0, column);
      }
    }
    if (localStorage.getItem("proIntroduce") !== null) {
      this.proIntroduce = localStorage.getItem("proIntroduce");
    }
    if (localStorage.getItem("productName") !== null) {
      this.infoForm.setFieldsValue({
        productName: localStorage.getItem("productName")
      });
    }
    if (localStorage.getItem("productCode") !== null) {
      this.infoForm.setFieldsValue({
        productCode: localStorage.getItem("productCode")
      });
    }
    if (localStorage.getItem("factoryId") !== null) {
      this.infoForm.setFieldsValue({
        factoryId: localStorage.getItem("factoryId")
      });
    }
    if (localStorage.getItem("onShelfStatus") !== null) {
      this.onShelfStatus = localStorage.getItem("onShelfStatus");
    }
  },
  methods: {
    // 获取全部制造原厂
    onLoadFactory() {
      fetchAllFactoriesList().then(resp => {
        if (resp.data.code === "SUCCESS") {
          this.factoryList = resp.data.data.records;
        }
      });
    },
    onJudgeAddSku() {
      this.addForm.resetFields();
      this.addVisible = true;
    },
    handleCancel() {
      this.visible = false;
    },
    handleOk() {
      this.visible = false;
    },
    onBack() {
      this.$router.push({ name: "CompleteVehicle" });
    },
    onModify() {
      if (this.buttonName === "修改") {
        this.breadcrumbTitle = "产品修改";
        this.buttonName = "完成";
        this.disabled = false;
        this.editorConfig.readOnly = false;
      } else if (this.buttonName === "完成") {
        this.infoForm.validateFields((err, values) => {
          if (!err) {
            const map = {};
            this.speciList.forEach(item => {
              const list = [];
              item.list.forEach(e => {
                list.push(JSON.stringify(e));
              });
              map[item.tagName] = list;
            });
            const param = {
              ...values,
              productType: "整车",
              onShelfStatus: parseInt(this.onShelfStatus),
              skuFormList: this.tblData,
              productDescription: this.proIntroduce,
              productId: this.productId,
              specifications: map ? map : {}
            };
            modifyProductDetail(param).then(resp => {
              if (resp.data.code === "FAILED") {
                this.$message.error(resp.data.errorMsg);
              } else {
                this.$store.state.hasChange = true;
                this.$message.success("修改成功");
                // this.$router.push({ name: "CompleteVehicle" });
                this.breadcrumbTitle = "产品详情";
                this.tableColumns = [...this.tblColumns];
                this.onDetail();
                this.disabled = true;
                this.editorConfig.readOnly = true;
                this.buttonName = "修改";
              }
            });
          }
        });
      }
    },
    onAdd() {
      this.$store.state.isModify = false;
      this.infoForm.validateFields((err, values) => {
        if (
          localStorage.getItem("tblData") &&
          JSON.parse(localStorage.getItem("tblData")).length > 0
        ) {
          if (!err) {
            const param = {
              ...values,
              productType: "整车",
              onShelfStatus: parseInt(this.onShelfStatus),
              skuFormList: this.tblData,
              productDescription: this.proIntroduce,
              specifications: this.getSpeciStr()
            };
            addProduct(param).then(resp => {
              if (resp.data.code === "SUCCESS") {
                localStorage.removeItem("tblData");
                localStorage.removeItem("productName");
                localStorage.removeItem("productCode");
                localStorage.removeItem("proIntroduce");
                localStorage.removeItem("keyPropsMap");
                localStorage.removeItem("partCode");
                localStorage.removeItem("onShelfStatus");
                localStorage.removeItem("specifications");
                this.$store.state.hasChange = true;
                this.$message.success("新增成功");
                this.$router.push({ name: "CompleteVehicle" });
              }
            });
          }
        } else {
          this.visible = true;
          this.ModalText = "请添加商品";
        }
      });
    },
    // 预览的sku图片
    async handlePreviewSkuImage(file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }
      this.previewImage = file.url || file.preview;
      this.previewVisible = true;
    },
    // 新增时图片信息
    handleChangeSkuImage({ fileList }) {
      this.skuImages = fileList.filter(item => item.fileUrl);
    },
    // 修改时展示的图片
    handleChangeSkuImg({ fileList }) {
      this.skuImgs = fileList.filter(item => item.fileUrl);
    },
    beforeUploadFile(file) {
      const size = file.size;
      const fileName = JSON.stringify(file.name);
      if (fileName.length > 100) {
        this.$message.error("图片名称的长度需在100个字以内");
      } else {
        if (size > 20 * 1024 * 1024) {
          this.$message.error("图片大小超过20MB");
        } else {
          this.uploadImage("product_picture", file);
        }
      }
      return false;
    },
    async uploadImage(type, file) {
      const { data = {} } = await upload(type, file, "other");
      if (data.code === "FAILED") {
        this.$message.error(data.errorMsg);
      } else {
        if (this.addVisible) {
          // 新增
          this.skuImages.push({
            uid: this.skuImages.length,
            name: file.name,
            url: imageBaseUrl + data.data,
            fileUrl: data.data,
            response: data
          });
        }
        if (this.modifyVisible) {
          // 编辑
          this.skuImgs.push({
            uid: this.skuImgs.length,
            name: file.name,
            url: imageBaseUrl + data.data,
            fileUrl: data.data
          });
        }
      }
    },
    // 提交新增商品
    onSubmitCommodity() {
      this.addForm.validateFields((err, values) => {
        if (!err) {
          if (this.skuImages.length === 0) {
            this.$message.info("请上传商品图");
          } else {
            const imgList = [];
            if (this.skuImages.length > 0) {
              this.skuImages.forEach(item => {
                const param = {
                  fileName: item.name,
                  fileUrl: item.response.data
                };
                imgList.push(param);
              });
            }
            const skuForm = {
              ...values,
              hasSn: Boolean(values.hasSn),
              hasBatchNo: Boolean(values.hasBatchNo),
              skuImages: imgList,
              bom: []
            };
            this.tblData.push(skuForm);
            localStorage.setItem("tblData", JSON.stringify(this.tblData));
            this.addVisible = false;
            this.addForm.resetFields();
            this.skuImages = [];
          }
          this.newSpeciList = [];
          JSON.parse(localStorage.getItem("tblData")).forEach(item => {
            this.newSpeciList.push(item.keyPropsMap);
          });
        }
      });
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    // 删除table里该行内容
    onDeleteTable(record) {
      localStorage.removeItem("tblData");
      this.tblData.forEach((item, index) => {
        if (item.skuCode === record.skuCode) this.tblData.splice(index, 1);
      });
      localStorage.setItem("tblData", JSON.stringify(this.tblData));
      this.newSpeciList = [];
      JSON.parse(localStorage.getItem("tblData")).forEach(item => {
        this.newSpeciList.push(item.keyPropsMap);
      });
    },
    // 添加规格
    addSpeci() {
      if (
        !localStorage.getItem("tblData") ||
        !JSON.parse(localStorage.getItem("tblData")).length > 0
      ) {
        this.speciVisible = true;
      } else {
        this.$message.error("请删除已有商品后进行操作");
      }
    },
    // 删除规格
    onDeleteSpeci(index) {
      if (
        !localStorage.getItem("tblData") ||
        !JSON.parse(localStorage.getItem("tblData")).length > 0
      ) {
        this.speciList.splice(index, 1);
        this.tableColumns.splice(index + 2, 1);
      } else {
        this.$message.error("请删除已有商品后进行操作");
      }
    },
    // 添加版本标签
    onAddVersionTag(params) {
      this.versionList.push(params.value);
    },
    // 删除版本标签
    onRemoveVersionTag(params) {
      if (params.value.label === "标准版") {
        this.$message.info("请勿删除");
        return;
      }
      this.versionList = this.versionList.filter(
        item => item.label !== params.value.label
      );
    },
    // 添加标签
    onAddTags(params) {
      this.speciList.forEach(item => {
        if (item.tagName === params.tagName) {
          item.list.push(params.value);
        }
      });
      if (this.speciList.length > 0) {
        const map = {};
        this.speciList.forEach(item => {
          const list = [];
          item.list.forEach(e => {
            list.push(JSON.stringify(e));
          });
          map[item.tagName] = list;
        });
        localStorage.setItem("specifications", JSON.stringify(map));
      }
    },
    // 删除标签
    onRemoveTags(params) {
      if (params.value.value === "标准版") {
        this.$message.info("请勿删除");
        return;
      }
      let isExist = false;
      if (this.newSpeciList.length > 0) {
        this.newSpeciList.forEach(items => {
          for (const key in items) {
            if (key === params.tagName) {
              if (items[key] === params.value.value) {
                isExist = true;
              }
            }
          }
        });
      }
      if (!isExist || this.tblData.length === 0) {
        this.speciList.forEach(item => {
          if (item.tagName === params.tagName) {
            item.list.forEach((tag, tagInd) => {
              if (tag.value === params.value.value) {
                item.list.splice(tagInd, 1);
              }
            });
          }
        });
        if (this.speciList.length > 0) {
          const map = {};
          this.speciList.forEach(item => {
            const list = [];
            item.list.forEach(e => {
              list.push(JSON.stringify(e));
            });
            map[item.tagName] = list;
          });
          localStorage.setItem("specifications", JSON.stringify(map));
        }
      } else {
        this.$message.error("请删除已有商品后进行操作");
      }
    },
    // 提交添加的规格
    onAddSpeci(e) {
      e.preventDefault();
      e.stopPropagation();
      this.speciForm.validateFields((err, values) => {
        if (!err) {
          const list = [];
          list.push({ value: values.value, label: values.value });
          this.speciList.push({
            tagName: values.tagName,
            list: list
          });
          const name = `keyPropsMap.${values.tagName}`;
          const item = {
            title: values.tagName,
            dataIndex: name,
            width: 150
          };
          this.tableColumns.splice(1 + this.speciList.length, 0, item);
          this.speciForm.setFieldsValue({
            tagName: "",
            value: ""
          });
          this.speciVisible = false;
        }
      });
      if (this.speciList.length > 0) {
        const map = {};
        this.speciList.forEach(item => {
          const list = [];
          item.list.forEach(e => {
            list.push(JSON.stringify(e));
          });
          map[item.tagName] = list;
        });
        localStorage.setItem("specifications", JSON.stringify(map));
      }
    },
    // 打开该record修改的表单
    onModifyVisible(record) {
      this.buttonName = "完成";
      this.skuCode = record.skuCode;
      this.modifyVisible = true;
      setTimeout(() => {
        this.modifyForm.setFieldsValue({
          skuCode: record.skuCode,
          wholeSellPrice: record.wholeSellPrice,
          retailPrice: record.retailPrice,
          platformPrice: record.platformPrice,
          keyPropsMap: record.keyPropsMap,
          skuVolume: record.skuVolume,
          packVolume: record.packVolume,
          skuWeight: record.skuWeight,
          hasSn: record.hasSn ? 1 : 0,
          hasBatchNo: record.hasBatchNo ? 1 : 0
        });
      }, 100);
      this.skuImgs = [];
      this.imgList = record.skuImages;
      if (record.skuImages && record.skuImages.length > 0) {
        record.skuImages.forEach((item, index) => {
          this.skuImgs.push({
            uid: index,
            name: item.fileName,
            url: imageBaseUrl + item.fileUrl,
            fileUrl: item.fileUrl
          });
        });
      }
    },
    // 提交修改的信息
    onModifyComm() {
      this.modifyForm.validateFields((err, values) => {
        if (!err) {
          if (this.skuImgs.length === 0) {
            this.$message.info("请上传商品图");
          } else {
            if (this.skuImgs.length > 0) {
              this.imgList = [];
              this.skuImgs.forEach(item => {
                this.imgList.push({
                  fileName: item.name,
                  fileUrl: item.fileUrl ? item.fileUrl : item.response.data
                });
                item.response = "";
              });
            }
            this.tblData.forEach(item => {
              if (item.skuCode === this.skuCode) {
                item.skuImages = this.imgList;
                item.wholeSellPrice = values.wholeSellPrice;
                item.retailPrice = values.retailPrice;
                item.platformPrice = values.platformPrice;
                item.keyPropsMap = values.keyPropsMap;
                item.skuVolume = values.skuVolume;
                item.packVolume = values.packVolume;
                item.skuWeight = values.skuWeight;
              }
            });
            this.skuImgs = [];
            this.modifyForm.resetFields();
            localStorage.setItem("tblData", JSON.stringify(this.tblData));
            this.newSpeciList = [];
            JSON.parse(localStorage.getItem("tblData")).forEach(item => {
              this.newSpeciList.push(item.keyPropsMap);
            });
            this.modifyVisible = false;
          }
        }
      });
    },
    // 进入bom表页面
    onDetailBOM(record) {
      this.$store.state.isModify = false;
      let productName = "";
      if (typeof this.infoForm.getFieldsValue().productName === "undefined") {
        productName = "";
      } else {
        productName = this.infoForm.getFieldsValue().productName;
      }
      let productCode = "";
      if (typeof this.infoForm.getFieldsValue().productCode === "undefined") {
        productCode = "";
      } else {
        productCode = this.infoForm.getFieldsValue().productCode;
      }
      let factoryId = "";
      if (typeof this.infoForm.getFieldsValue().factoryId === "undefined") {
        factoryId = "";
      } else {
        factoryId = this.infoForm.getFieldsValue().factoryId;
      }
      if (record.keyPropsMap) {
        localStorage.setItem("keyPropsMap", JSON.stringify(record.keyPropsMap));
      }
      let obj = {
        tagName: "版本",
        list: this.versionList
      };
      let taglist = [obj, ...this.speciList];
      if (taglist.length > 0) {
        const map = {};
        taglist.forEach(item => {
          const list = [];
          item.list.forEach(e => {
            list.push(JSON.stringify(e));
          });
          map[item.tagName] = list;
        });
        localStorage.setItem("specifications", JSON.stringify(map));
      }
      localStorage.setItem("proIntroduce", this.proIntroduce);
      localStorage.setItem("productName", productName);
      localStorage.setItem("productCode", productCode);
      localStorage.setItem("factoryId", factoryId);
      localStorage.setItem("partCode", record.skuCode);
      localStorage.setItem("onShelfStatus", this.onShelfStatus);
      this.$router.push({ name: "AddBOM", params: { type: "add" } });
    },
    // 查询输入的编码
    onJudgeCommon() {
      const skuCode = this.addForm.getFieldsValue().skuCode;
      const factoryId = localStorage.getItem("factoryId");
      checkCommonSku(factoryId, skuCode).then(resp => {
        if (resp.data.code === "SUCCESS") {
          if (resp.data.data === true) {
            this.$message.info("输入的商品编码已存在，请重新输入");
            this.addForm.setFieldsValue({ skuCode: "" });
          }
        }
      });
    },
    // 校验产品编码是否存在
    checkProductCodeExists(rule, value, callback) {
      if (value) {
        checkProductCodeExists(value).then(resp => {
          if (resp.data.code === "SUCCESS") {
            const data = resp.data.data;
            if (data) {
              callback("产品编码已存在");
            } else {
              callback();
            }
          }
        });
      }
    },
    // 上移规格
    moveUpSpeci(index) {
      const indexItem = this.speciList[index];
      const prevItem = this.speciList[index - 1];
      this.speciList = this.speciList.map((item, i) => {
        if (i === index - 1) return indexItem;
        if (i === index) return prevItem;
        return item;
      });
    },
    // 下移规格
    moveDownSpeci(index) {
      const indexItem = this.speciList[index];
      const nextItem = this.speciList[index + 1];
      this.speciList = this.speciList.map((item, i) => {
        if (i === index) return nextItem;
        if (i === index + 1) return indexItem;
        return item;
      });
    },
    // 把规格转成字符串
    getSpeciStr() {
      let flag = this.speciList.some(item => {
        return item.list.some(n => n.value === "标准版");
      });
      if (flag) {
        return JSON.stringify([
          ...(this.speciList.map(item => ({
            [item.tagName]: item.list.map(v => v.value)
          })) || [])
        ]);
      } else {
        return JSON.stringify([
          { 版本: this.versionList.map(v => v.value) },
          ...(this.speciList.map(item => ({
            [item.tagName]: item.list.map(v => v.value)
          })) || [])
        ]);
      }
    },
    showRow() {
      let flag = true;
      this.speciList.forEach(item => {
        if (item.tagName === "版本") {
          flag = false;
        }
      });
      return flag;
    }
  }
};
</script>

<style scoped>
.breadcrumb-title {
  cursor: pointer;
}
.info-container {
  background-color: white;
  margin: 10px 20px;
  box-sizing: border-box;
  padding: 25px 30px 5px 30px;
}
.info-title {
  font-weight: bold;
  margin-bottom: 10px;
}
.ant-row {
  display: flex !important;
}
/deep/ .ant-form-item .ant-form-item-label {
  text-align: left !important;
  min-width: 80px !important;
}
/deep/ .form-item .ant-form-item-control {
  width: 90% !important;
}
.pro-status {
  background-color: white;
  margin: 10px 20px;
  box-sizing: border-box;
  padding: 25px 30px 25px 30px;
}
.commodity-info {
  background-color: white;
  margin: 10px 20px;
  box-sizing: border-box;
  padding: 25px 30px 25px 30px;
}
.commodity-introduce {
  background-color: white;
  margin: 10px 20px 60px 20px;
  box-sizing: border-box;
  padding: 25px 30px 25px 30px;
}
.commodity-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.commodity-table {
  margin-top: 20px;
}
.examine-container {
  background-color: white;
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 10px;
  z-index: 1;
  display: flex;
  justify-content: flex-end;
}
/*/deep/ .ant-upload-picture-card-wrapper {*/
/*  display: flex;*/
/*}*/
.img-container {
  width: 100px;
  height: 100px;
}
.img-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.commodity-specifications {
  background-color: white;
  margin: 10px 20px;
  box-sizing: border-box;
  padding: 25px 30px 25px 30px;
}
.add-speci-tag {
  background-color: #4368f6;
  color: white;
  margin-bottom: 10px;
  cursor: pointer;
}
.commodity-content {
  margin-bottom: 10px;
}
.tag-name-container {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}
.add-speci-container /deep/ .ant-form-item .ant-form-item-label {
  min-width: 60px;
}
.add-speci-container .ant-col {
  height: 60px;
}
/deep/ .advanced-search-form .ant-form-item {
  display: flex;
}
.tag-name-container img {
  cursor: pointer;
  width: 20px;
  object-fit: contain;
}
.tag-name-container .tag-name-button {
  color: #4368f6;
  margin-left: 10px;
  cursor: pointer;
}
/deep/ .advanced-search-form .ant-form-item {
  display: flex;
}

/deep/ .ant-form-item .ant-form-item-control-wrapper {
  flex: 1;
}
/deep/ .ant-form-item .ant-form-item-label {
  text-align: right;
  min-width: 100px;
}
/deep/ .ant-table-tbody tr {
  min-height: 121px !important;
  max-height: 121px !important;
}
</style>
